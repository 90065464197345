import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const UsingDSG = () => (
  <Layout>
    <Seo title="Using DSG" />
  </Layout>
)

export default UsingDSG
